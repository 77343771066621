import React from 'react'

import './legal-cover.css'

const LegalCover = ({ title }) => {
  return (
    <div className="legal-cover">
      <div className="pt-8"></div>
      <h2 className="text-center text-3xl font-bold flex-auto py-24 md:py-32 lg:py-48">{title}</h2>
    </div>
  )
}

export default LegalCover
