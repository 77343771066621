import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

import TOSContent from "../components/legal/tos-content.js"
import LegalCover from "../components/legal/legal-cover.js"

const TOS = () => {
  return (
    <Layout>

      <SEO title="Terms of Use - Credit 101" keywords={[`credit`, `card`, `score`, `build`]} description="By accessing this website we assume you accept these terms and conditions in full. Do not continue to use Credit 101's website if you do not accept all of the terms and conditions stated on this page."/>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <LegalCover title="Terms of Use"></LegalCover>
      <TOSContent></TOSContent>
    </Layout>
  )
}

export default TOS
