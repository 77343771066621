/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import Backdrop from "../components/backdrop"
import NavDrawer from "../components/nav-drawer"

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNavOpen: false,
      scrolled: false,
      scrollingDown: false
    };
    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  toggleMobileNav() {
    this.setState((prevState) => {
      return {mobileNavOpen: !prevState.mobileNavOpen}
    });
  }

  componentDidMount() {
    this.prevScroll = window.scrollY;
      window.addEventListener('scroll', e => this.handleScroll(e));
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', e => this.handleScroll(e));
  }

  handleScroll = (e) => {
    const window = e.currentTarget;
    if (window.scrollY === 0) {
      this.setState({
        scrolled: false
      });
    }
    else {
      this.setState({
        scrolled: true
      });

      if(this.prev > window.scrollY){
        this.setState({
          scrollingDown: false
        });
      }
      else if(this.prev < window.scrollY){
        this.setState({
          scrollingDown: true
        });
      }
      this.prev = window.scrollY;
    }
  }

  render() {
    Layout.propTypes = {
      children: PropTypes.node.isRequired,
    }
    return (
      <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <NavDrawer navDrawerShow={this.state.mobileNavOpen}/>
          <Backdrop navDrawerShow={this.state.mobileNavOpen} toggleHandler={this.toggleMobileNav}/>
          <Header toggleHandler={this.toggleMobileNav} scrolled={this.state.scrolled} scrollDown={this.state.scrollingDown} isRelative={false} isHome={this.props.isHome}/>
          <div>
            <main>{this.props.children}</main>
            <Footer></Footer>
          </div>
        </>
      )}
      />
    )
  }
}

export default Layout
